<template>
  <div class="registration">
    <!--StartFragment-->
    <div class="Section0">
      <p
        class="MsoNormal"
        align="center"
        style="margin-top: 24pt; margin-bottom: 24pt; text-align: center"
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 26pt;
            "
            ><font face="宋体">听股票服务协议</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="margin-left: 0pt; text-align: left; mso-list: l0 level1 lfo1"
      >
        <span
          style="
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            color: rgb(0, 112, 240);
            font-size: 11pt;
          "
          ><span style="mso-list: Ignore"
            >&#8226;<span>&nbsp;</span></span
          ></span
        ><span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">更新日期：</font>2022<font face="宋体">年</font
          ><font face="Times New Roman">05</font><font face="宋体">月</font
          ><font face="Times New Roman">05</font><font face="宋体">日</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="margin-left: 0pt; text-align: left; mso-list: l1 level1 lfo2"
      >
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">生效日期：</font>2022<font face="宋体">年</font
          ><font face="Times New Roman">05</font><font face="宋体">月</font
          ><font face="Times New Roman">05</font><font face="宋体">日</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l2 level1 lfo3;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">一、特别提示</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1.<font face="宋体">《听股票服务协议》（以下简称</font
          ><font face="Times New Roman">&#8220;</font
          ><font face="宋体">本协议</font
          ><font face="Times New Roman">&#8221;</font
          ><font face="宋体">）是用户（以下或称</font
          ><font face="Times New Roman">&#8220;</font><font face="宋体">您</font
          ><font face="Times New Roman">&#8221;</font
          ><font face="宋体">）与听股票（其定义详见本协议第二条第（</font
          ><font face="Times New Roman">1</font
          ><font face="宋体"
            >）款）之间签署的具有法律约束力的协议。本协议为适用于听股票提供的所有服务（以下简称</font
          ><font face="Times New Roman">&#8220;</font
          ><font face="宋体">服务</font
          ><font face="Times New Roman">&#8221;</font><font face="宋体">或</font
          ><font face="Times New Roman">&#8220;</font
          ><font face="宋体">听股票提供的服务</font
          ><font face="Times New Roman">&#8221;</font
          ><font face="宋体">）的基础性、通用性的协议，您注册听股票账号和</font
          ><font face="Times New Roman">/</font
          ><font face="宋体">或使用听股票提供的任何服务均受本协议之约束。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2.<font face="宋体">听股票特别提示您：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>1<font face="宋体"
            >）请您务必审慎阅读本协议的全部条款，特别是免除或者限制听股票的责任的条款、排除或者限制您的权利的条款、以及约定适用法律、争议解决方式和管辖法院的条款等，这些条款可能以黑体加粗、设置下划线等方式被突出标示；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>2<font face="宋体"
            >）请您务必审慎选择是否接受本协议。如果您在相关页面点击确认本协议、或在注册</font
          ><font face="Times New Roman">/</font
          ><font face="宋体"
            >登录过程中作出（或被听股票视为作出）同意本协议的意思表示、或在听股票以合理方式向您展示</font
          ><font face="Times New Roman">/</font><font face="宋体">提示</font
          ><font face="Times New Roman">/</font
          ><font face="宋体"
            >通知本协议或本协议的任何更新版本后仍继续使用听股票提供的服务、或以可能的其他方式作出（或被听股票视为作出）同意本协议的意思表示，均代表您完全同意本协议的全部条款；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>3<font face="宋体"
            >）如果您对本协议的任何部分有任何异议，您应当立即停止使用听股票提供的服务。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3.<font face="宋体">听股票特别提示您：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>1<font face="宋体"
            >）为了给您提供更好的服务，听股票的业务可能不时发生变化，本协议也可能随之作出调整。听股票会通过在听股票网站（其定义详见本协议第二条第（</font
          ><font face="Times New Roman">2</font
          ><font face="宋体"
            >）款）发布更新版本或以其他方式提示您相关内容的更新，也请您访问听股票网站以便及时了解最新的协议文本；但您理解并同意，听股票无义务对您或任何特定用户作出特定通知并就相关内容的更新取得您或任何特定用户的同意；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>2<font face="宋体"
            >）在前述情况下，如果您继续使用听股票提供的服务，即表示您同意接受修订后的协议文本并愿意受其约束。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4.<font face="宋体"
            >如果您未注册听股票账号，将导致您不能成为听股票的用户，但这并不会导致听股票禁止您访问听股票网站并使用基本的浏览或信息搜索功能。然而，在任何情形下，您都充分理解并完全同意，听股票无意且不应被认定为向任何未注册听股票账号的对象提供任何服务或与之建立任何商业关系。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>3<font face="宋体"
            >）您在使用听股票的特定服务时，该等服务可能会另有单独适用的相关协议、政策、指引、制度或规则等。所有听股票已经发布的或将来可能发布的涉及相关业务的协议、政策、指引、制度或规则等，均构成对本协议的有效补充，与本协议具有同等法律效力，对您具有法律上的约束力。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l3 level1 lfo4;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">二、定义与释义</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体">、本协议所称</font
          ><font face="Times New Roman">&#8220;</font
          ><font face="宋体">听股票</font
          ><font face="Times New Roman">&#8221;</font
          ><font face="宋体"
            >是指听股票网站经营者北京听股票信息科技有限公司及现有和或将来设立的相关关联公司的单称或合称。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体">、本协议所称</font
          ><font face="Times New Roman">&#8220;</font
          ><font face="宋体">听股票网站</font
          ><font face="Times New Roman">&#8221;</font
          ><font face="宋体">包括但不限于听股票网（当前网址为</font
          ><font face="Times New Roman">www.tinggupiao.com.cn</font
          ><font face="宋体"
            >，如果将来听股票变更网址或增加其他网址，请以届时的网址为准）、听股票网对应的移动客户端（当前名称为听股票股票</font
          ><font face="Times New Roman">App</font
          ><font face="宋体"
            >，如果将来听股票变更移动客户端的名称或增加新的移动客户端，请以届时的情形为准）、以及相关的微信公众号或其他公众号平台、微信小程序或其他小程序、以及可能不时涉及的其他互联网媒介等。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l4 level1 lfo5;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">三、服务内容</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、一般情况下，听股票向用户提供的服务为免费服务。听股票提供的免费服务包括但不限于：（</font
          ><font face="Times New Roman">1</font
          ><font face="宋体"
            >）为用户提供互联网信息存储空间及信息发布技术服务，用户通过使用听股票提供的服务可以以文字、图片等形式发布资讯、文章、日记、博客、评论等；（</font
          ><font face="Times New Roman">2</font
          ><font face="宋体"
            >）听股票提供的其他免费服务等。在法律允许的最大范围内，听股票有权对提供的免费服务的范围或具体服务形态、方式、标准等进行升级或作其他调整。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、如果听股票向用户提供收费服务、或者将免费服务变更为收费服务，听股票会事先向用户明确提示相应的协议、政策、指引、制度或规则。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l5 level1 lfo6;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">四、服务变更、中断或终止</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、受限于互联网的固有缺陷（包括但不限于软硬件故障、网络通信线路故障、恶意网络攻击、不可抗力、或其他听股票无法控制的情形），您同意听股票有权依据自身判断在必要时变更、中断或终止部分或全部服务（包括收费服务），如果发生此等情形，听股票将尽可能及时通过网页公告、系统通知、私信、短信或听股票认为合理的其他方式向受影响的用户进行通知或提示。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、您同意，听股票需要定期或不定期对听股票网站和相关软硬件进行维护、升级，或基于其他合理原因需要暂停部分或全部服务，如果因此类情况而造成服务在听股票认为合理的时间内的中断或不稳定，听股票无需为此承担任何责任。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、如果发生下列任何一种情形，听股票有权随时中断或终止向您提供部分或全部服务（包括收费服务和免费服务）而无需对您或任何第三方承担任何责任，由此造成的损失完全由您自行承担：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>1<font face="宋体"
            >）您提供的个人资料不真实；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>2<font face="宋体"
            >）您违反法律法规、监管要求或本协议；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>3<font face="宋体"
            >）您在使用收费服务时未按规定为其所使用的收费服务支付相关服务费用；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>4<font face="宋体"
            >）您侵犯个人、企业事业单位或社会团体的合法权益，包括但不限于专利权、著作权、商标权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>5<font face="宋体"
            >）您损害监管部门、国家机关及政府形象；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>6<font face="宋体"
            >）您以任何方式损害听股票的商誉或信誉等合法权益；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>7<font face="宋体"
            >）听股票有其他合理理由认为需要中断或终止向您提供听股票提供的服务的。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4<font face="宋体"
            >、您同意，您与听股票的服务关系终止后，听股票仍享有下列权利：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>1<font face="宋体"
            >）继续保存您未及时删除的注册信息及您使用听股票提供的服务期间发布的所有信息至法律规定的记录保存期满；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>2<font face="宋体"
            >）您在使用听股票提供的服务期间存在违法行为或违反本协议和</font
          ><font face="Times New Roman">/</font
          ><font face="宋体"
            >或规则的行为的，听股票仍可依据本协议向您主张权利、追究责任。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l6 level1 lfo7;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">五、账号注册与认证</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、您在使用听股票提供的服务前需要注册听股票账号成为听股票用户。听股票为您提供的注册方式如下：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>1<font face="宋体"
            >）通过手机号码进行注册。如果您是个人用户，您可以通过在相关网络页面输入您本人名下的实名制手机号并回填听股票发送给您的验证码进行注册（为此您必须保证：</font
          ><font face="Times New Roman">a</font
          ><font face="宋体"
            >、该手机号已经以您本人名义在相应电信服务商处进行了实名登记、并且该手机号正在由您本人使用；</font
          ><font face="Times New Roman">b</font
          ><font face="宋体"
            >、该手机号的归属地不属于欧盟境内任一成员国，听股票无意向欧盟境内任一成员国提供本协议项下约定之任何服务，更无意收集、处理、存储、披露、共享欧盟境内任一成员国所属之个人数据信息）；如果您是非个人用户，您需要遵守听股票有关非个人用户注册和实名认证的相关规定和要求；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>2<font face="宋体">）通过微微信、</font
          ><font face="Times New Roman">QQ</font
          ><font face="宋体"
            >、以及其他第三方账号（如有）进行注册。为了您的便利，听股票允许您通过微信、</font
          ><font face="Times New Roman">QQ</font
          ><font face="宋体"
            >、以及其他第三方账号（如有）进行注册（为此您必须保证该等三方账号已经以您本人的名义在相应第三方平台完成了实名认证、并且该等第三方账号正在由您本人使用）。在您通过第三方账号注册之后，您可以将您的听股票账号与您本人的微信、</font
          ><font face="Times New Roman">QQ</font
          ><font face="宋体"
            >、邮箱、手机号、以及其他第三方账号（如有）等账号进行绑定，后续您可以通过您绑定的相关账号登录听股票网站。进行账号绑定有助于提升您登录听股票网站的便利性，但不进行绑定也不会影响您使用我们的服务。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、您在注册听股票账号，完善账号昵称、头像、简介等信息时，不得以任何形式违反法律法规或侵害他人合法权益，不得出现违法和不良信息；否则，听股票有权随时不经通知对您的听股票账号进行处理。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、根据法律法规，您在注册账号时应当提供真实身份信息，不得冒用他人身份（包括不限于冒用他人姓名或肖像、冒用其他公司或机构的名称、盗用他人手机号码或第三方账号、盗用他人邮箱、冒用他人肖像或社会身份等）。如果听股票发现您未提供真实身份信息或者您冒用他人身份，听股票将终止为您提供服务。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4<font face="宋体"
            >、您基于信息登记或其他需要可以为其注册的听股票账号申请听股票平台认证。申请账号认证需按照要求提交相关资料，完成所有审核流程后，由听股票作出认证成功与否的判断。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >5<font face="宋体"
            >、听股票平台认证服务内容仅限于对用户提交的资料及信息进行有限的甄别与核实，听股票将对您提交的资料和信息进行合理、谨慎的形式审查，但在听股票的合法权限和合理能力范围内，听股票可能无法实质审查用户的实际经营、运营、提供服务以及推广等行为，并不为此提供任何担保。因用户行为导致与其他用户或第三方发生争议的，由用户独立承担全部责任；因此给听股票、其他用户或第三方造成损害的，您应当承担完全的赔偿责任。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l7 level1 lfo8;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">六、免责声明</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、您理解并同意：听股票所提供的任何信息（包括但不限于听股票官方提供的、听股票用户发布的、听股票邀请的嘉宾提供的），听股票并不能保证其完全实时或完全准确，也不表明听股票证实其描述或赞同其观点。所有内容仅供参考，不构成投资建议或者其他实际的操作意见，用户据此操作所造成的后果自行负责。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、您理解并同意：听股票不保证服务一定能满足用户的要求，也不保证服务不会中断，对服务的及时性、准确性不作保证。您使用听股票提供的服务所存在或导致的风险将完全由您自己承担；因您使用听股票提供的服务而产生的一切后果也由您自己承担，听股票对您不承担任何责任，该等风险或后果包括但不限于：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>1<font face="宋体"
            >）由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等不可抗拒因素可能引起的信息丢失、泄漏等损失和风险；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>2<font face="宋体"
            >）您或听股票的电脑软件、系统、硬件和通信线路出现故障导致的服务中断、资料丢失、数据损毁以及其他的损失和风险；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>3<font face="宋体"
            >）您操作不当或通过非听股票授权的方式使用听股票提供的服务带来的损失和风险；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>4<font face="宋体"
            >）您发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>5<font face="宋体"
            >）由于网络信号不稳定等原因，所引起的听股票账号登录失败、资料同步不完整、页面打开速度慢等风险；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>6<font face="宋体"
            >）其他听股票无法控制或合理预见的情形。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、您理解并同意：您通过听股票发布的内容可能会被其他用户或第三方复制、转载、修改或作其他用途，脱离您的预期和控制，您应充分意识到此类风险的存在，任何您不愿被他人获知的信息都不应在听股票发布。如果相关行为侵犯了您的合法权益，您可以向听股票投诉，听股票将依法进行处理并在可行的范围内为您的维权提供便利，但听股票并不对维权时效、结果等作任何保证或承诺。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4<font face="宋体"
            >、您理解并同意：除法律法规或本协议另有规定外，在任何情况下，听股票需向您承担的违约和侵权赔偿责任的总额不应超过向您收取的当次服务的费用总额。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l8 level1 lfo9;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">七、知识产权及版权处理</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、听股票网站的内容（包括但不限于网页、界面设计、版面框架、文字、音频、视频、图片或其他资料等）等知识产权归听股票或相关权利人所有。除另有约定外，听股票网站的软件、系统等的著作权、专利权及其他知识产权归听股票所有。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、您使用听股票提供的服务在听股票上发布的文字、音频、视频、图片、图表等任何内容均应由您原创或已获得合法授权并且该内容不会侵犯任何第三方的合法权益，您通过听股票上传、发布的任何内容的知识产权归属于您本人或原始权利人所有。如有第三方声称您上传、发布的内容侵犯了第三方的知识产权或其他权利、并向听股票提供了相应的证明材料或说明（听股票将对第三方提供的证明材料或说明进行合理的形式审查，但在听股票的合法权限和合理能力范围内，听股票可能无法实质审查相应内容是否确实侵权或确实不侵权，无论听股票最终作出何种处理措施，您均理解并同意，听股票无需为此向您承担任何责任。），听股票有权基于自身判断对可能涉嫌侵权的内容作删除、屏蔽等处理，并采取包括但不限于关停听股票账号等措施对您进行惩罚；同时，侵权责任由信息发布主体自行承担，听股票不承担任何责任。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、您确认并同意本协议时，即表明您授予听股票获得您所上传、发布的全部内容之全世界范围内免费的、永久性的、不可撤销的、非独家的、可转授权的、完全许可的权利和许可。为促进内容传播、分享及观点交流，听股票有权以商业或非商业目的将内容用于其他合法用途，包括但不限于部分或者全部地复制、修改、改编、翻译、组装、分拆、推广、分发、广播、表演、演绎、出版及转授权，并将内容用于以下范围，包括但不限于听股票或听股票入驻</font
          ><font face="Times New Roman">/</font
          ><font face="宋体"
            >合作的任何第三方客户之网站、移动应用程序、微信公众号或其他公众号、微信小程序或其他小程序、产品或终端设备等，并授权听股票及其关联公司、合作公司对相应内容可进行修改、复制、改编、翻译、汇编或制作衍生产品等。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4<font face="宋体"
            >、如果第三方出于非商业目的对用户发布在听股票上的内容进行转载，需事先经用户或听股票书面授权同意；同时，第三方在转载时应当在显著位置注明原作者姓名（或原作者的</font
          ><font face="Times New Roman">&#8220;</font
          ><font face="宋体">听股票账号</font
          ><font face="Times New Roman">&#8221;</font
          ><font face="宋体">昵称），附载文章原始链接，及注明</font
          ><font face="Times New Roman">&#8220;</font
          ><font face="宋体">发布于听股票</font
          ><font face="Times New Roman">&#8221;</font
          ><font face="宋体"
            >，且不得擅自修改文章内容。若第三方出于商业目的进行转载的，或需要对文章进行修改的，应联系听股票或用户进行单独授权，并按听股票或用户要求使用该内容。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >5<font face="宋体"
            >、您同意许可听股票视具体情况就任何主体侵犯您权益的事宜进行维权，包括但不限于发送侵权函件、提起诉讼、申请仲裁、以及其他合理措施等，听股票无需再向您单独获得授权。本协议已经构成《中华人民共和国著作权法》第二十五条所规定的书面协议，其效力及于用户在听股票发布的任何受著作权法保护的内容，无论该内容形成于本协议签订前还是本协议签订后。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >6<font face="宋体"
            >、听股票尊重他人合法权益（包括知识产权、名誉权、商誉权等），呼吁您也尊重他人合法权益。如果您违反法律法规的禁止性规定、对他人的合法权益或公众利益造成了侵害，听股票将依法律法规的规定，或在适当的情形下，依本协议或其相关规定，删除或屏蔽特定内容、直至终止您对听股票账号的使用。如果您认为用户通过听股票上传、发布的内容侵犯了您的合法权益，按照指引提示提交相关材料，听股票将依法进行处理。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l9 level1 lfo10;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">八、用户责任和义务</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、听股票致力于为您提供文明健康、规范有序的网络环境，您对听股票提供的服务的使用必须遵守法律法规。您不得利用听股票账号或听股票提供的服务从事如下行为：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>1<font face="宋体"
            >）发布、传送、传播、储存违反国家法律法规禁止的内容：</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >a.<font face="宋体">违反宪法确定的基本原则的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >b.<font face="宋体"
            >危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >c.<font face="宋体">损害国家荣誉和利益的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >d.<font face="宋体">煽动民族仇恨、民族歧视，破坏民族团结的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >e.<font face="宋体">破坏国家宗教政策，宣扬邪教和封建迷信的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >f.<font face="宋体">散布谣言，扰乱社会秩序，破坏社会稳定的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >g.<font face="宋体"
            >散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >h.<font face="宋体">侮辱或者诽谤他人，侵害他人合法权益的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >i.<font face="宋体"
            >煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >j.<font face="宋体">以非法民间组织名义活动的；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >k.<font face="宋体">含有法律、行政法规禁止的其他内容的。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>2<font face="宋体"
            >）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>3<font face="宋体"
            >）发布、传送、传播、储存涉及他人隐私、个人信息或资料的内容；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>4<font face="宋体"
            >）发布、传送、传播骚扰信息、广告信息、垃圾信息、或含有任何性或性暗示信息的内容；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>5<font face="宋体"
            >）发布、传送、传播谣言、虚假信息或其他含有不实信息的内容；</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体">（</font>6<font face="宋体"
            >）发布、传送、传播、储存其他违反法律法规、政策及公序良俗、社会公德或干扰听股票网正常运营和侵犯其他用户或第三方合法权益内容的信息。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、您承诺：遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等七条底线的要求。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、您理解并同意：您发表在听股票上的任何内容，听股票可以根据自己标准和立场进行管理或者删除，而无需提前或者事后向您说明；管理或者删除的标准，也完全由听股票独立制定，无需征求您意见，也无需向您公开。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4<font face="宋体"
            >、如果听股票发现用户通过听股票发布、传送、传播、储存的信息属于本条第</font
          ><font face="Times New Roman">1</font><font face="宋体">款第</font
          ><font face="Times New Roman">(1)</font><font face="宋体">项及第</font
          ><font face="Times New Roman">2</font
          ><font face="宋体"
            >款所列内容之一，听股票有义务依法立即停止传输，保存有关记录，进行相应处理并向国家有关机关报告。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >5<font face="宋体"
            >、因您的原因导致的任何纠纷、责任等，以及您或您的听股票账号违反法律法规或本协议约定引发的任何后果，均由您独立承担全部责任，与听股票无关；因此给听股票、其他用户或第三方造成损害的，您应当承担完全的赔偿责任。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l10 level1 lfo11;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">九、账号管理</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、您完成听股票账号申请注册手续后，您的听股票账号由您使用、运营、维护并独立承担全部责任，账号禁止赠与、借用、租用、转让或售卖等。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、听股票账号密码由您自行设定。听股票特别提示您应妥善保管您的账号和密码。听股票与您共同负有维护账号安全的责任。听股票会采取并不断更新技术措施，努力保护您的账号在服务器端的安全。您需要采取特定措施保护您的账号安全，包括但不限于妥善保管听股票账号与密码、安装防病毒软件、定期更改密码等措施。当您使用完毕后，应及时退出。您同意在任何情况下不向他人透露账号或密码信息。因您保管不善可能导致账号被他人使用（包括但不限于遭受盗号、密码失窃）或信息数据泄漏，责任由您自行承担。您理解并同意，在您未进行投诉或提出账号申诉等方式明确告知听股票账号被他人使用或信息数据泄漏等情况并提供相关证明材料前，听股票有理由相信该账号行为是您使用账号的行为。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、在您怀疑他人在使用您的账号或密码时，您同意立即通知听股票。如果您当前使用的听股票账号并不是您初始申请注册的或者通过听股票提供的其他途径获得的，但您却知悉该账号当前的密码，您不得用该账号登录或进行任何操作，并请您在第一时间通知听股票或者该账号的初始申请注册主体。如果听股票发现您并非该账号的初始申请注册主体，听股票有权在未经通知的情况下终止您使用该账号。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4<font face="宋体"
            >、您理解并同意，为保护您及其他用户的数据安全，防止用户信息泄露、毁损、篡改或者丢失，听股票有权对您接入的信息系统实行接入审查，包括但不限于技术水平审查、安全水平审查、主体资质审查等，并根据审查结果向您提出防入侵、防病毒等措施建议。如果您的信息系统仍无法符合保护用户数据安全的要求，听股票有权拒绝或终止向您提供服务。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >5<font face="宋体"
            >、如果您违反法律法规、本协议以及专项规则的规定，听股票有权进行独立判断并随时限制、冻结或终止您对听股票账号的使用，且根据实际情况决定是否恢复使用。由此给您带来的损失（包括但不限于通信中断，用户资料及相关数据清空等），由您自行承担。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >6<font face="宋体"
            >、如果您的听股票账号被盗、密码遗忘或因其他原因导致无法正常登录，您可以按照听股票的申诉途径进行申诉。听股票将在可行的范围内为您的申诉提供便利，但并不承诺您一定能通过申诉找回账号。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l11 level1 lfo12;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">十、账号的有效期限和注销</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1.<font face="宋体"
            >为了充分利用账号资源，如果您存在注册听股票账号后连续超过</font
          ><font face="Times New Roman">3</font
          ><font face="宋体"
            >个月没有登录使用听股票账号等情形，听股票有权终止该账号的使用，而无需另行通知您。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2.<font face="宋体"
            >您有权注销您的听股票账号。您可以通过听股票设置内注销账号申请注销或者以及听股票不时提供的其他方式（如有）与听股票取得联系。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3.<font face="宋体"
            >在您主动注销账号之后，听股票将停止为您提供服务，并根据法律的规定删除您的个人信息，法律另有规定的除外。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l12 level1 lfo13;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">十一、通知和送达</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、您理解并同意，听股票可能会通过包括但不限于软件页面提示、网站公告、系统通知、官方管理账号通知（公开或者私下）、电子邮件、手机短信、站内信等通知方式中的一种或多种向您送达相关通知、提示等信息。通知成功发出时，即被视为已送达您。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、您理解并同意，任何由于电子邮箱、手机号码、通讯地址等信息错误或其他不可归咎于听股票的原因，导致您未收到相关规则、通知、提示等信息的，均不影响该等信息对您所产生的法律效力。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、您理解并同意，听股票可向您的电子邮箱、手机号码、第三方账号等发送商业性电子信息（包括但不限于商业广告等）。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l13 level1 lfo14;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">十二、法律责任</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >1<font face="宋体"
            >、如果听股票发现或收到他人投诉用户违反本协议约定、法律法规及监管要求的，听股票有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，并公告处理结果。听股票也有权依照本协议及专项规则的规定，拒绝再向该主体提供服务。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >2<font face="宋体"
            >、除上述处罚措施外，听股票有权取消其用户听股票账号认证身份。如果您发现任何人违反本协议规定或以其他不当的方式使用听股票提供的服务，请立即向听股票投诉。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >3<font face="宋体"
            >、您理解并同意，听股票有权依合理判断对违反法律法规或本协议规定的行为进行处罚，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >4<font face="宋体"
            >、您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；听股票因此遭受损失的，您也应当一并赔偿。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          >5<font face="宋体"
            >、您理解并同意，为及时保障您合法权益不受侵害，如果您的听股票账号存在权益纠纷，听股票有权根据情况，采取包括但不限于冻结该账号的部分或全部功能等保护措施，直至有权机关作出生效裁判或用户在不违反本协议的前提下协商一致，并由此所产生的一切法律责任均与听股票无关。</font
          ><font face="Times New Roman"> </font></span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l14 level1 lfo15;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">十三、适用法律和争议解决方式</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体"
            >本协议适用中华人民共和国的法律，并且排除一切冲突法规定的适用。如出现纠纷，应由听股票与用户协商处理；协商不成时，您和听股票一致同意将纠纷交由北京市朝阳区人民法院管辖。</font
          > </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p
        class="MsoNormal"
        style="
          margin-top: 13pt;
          margin-bottom: 6pt;
          margin-left: 0pt;
          text-align: left;
          mso-outline-level: 4;
          mso-list: l15 level1 lfo16;
        "
      >
        <b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'Times New Roman';
              mso-fareast-font-family: 宋体;
              font-weight: bold;
              font-size: 14pt;
            "
            ><font face="宋体">十四、解释权</font>
          </span></b
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
          ><font face="宋体"
            >在法律允许的最大范围内，听股票保留对本协议及相关政策、指引、规则的最终解释权。</font
          > </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
      <p class="MsoNormal" style="text-align: left">
        <span
          style="
            mso-spacerun: 'yes';
            font-family: 'Times New Roman';
            mso-fareast-font-family: 宋体;
            font-size: 11pt;
          "
        >
        </span
        ><span
          style="mso-spacerun: 'yes'; font-family: Calibri; font-size: 10.5pt"
          ><o:p></o:p
        ></span>
      </p>
    </div>
    <!--EndFragment-->
  </div>
</template>
<script>
import { requestDataTGPMethod, isDev } from "@/utils/appManager";
export default {
  name: "registration",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>
<style scoped  lang="scss" >
.registration {
  padding-left: 16px;
  padding-right: 16px;
}
</style>